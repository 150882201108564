import React, { useContext, useEffect } from 'react';
import ServiceItem from './serviceItem'; // Import the ServiceItem component with correct capitalization
import AddonItem from './addonItem';
import { ServiceContext } from '../../context/Contexts';
import '../../css/Services.css'; // Import the CSS file for styling
import { Helmet } from 'react-helmet';

export const Services = () => { // Capitalize the component name
    const serviceContext = useContext(ServiceContext); // Use the ServiceContext
    const { services, loadServices, addons, loadAddons } = serviceContext; // Destructure services and loadServices from context

    useEffect(() => {

        loadServices(); // Load services when the component mounts
        loadAddons(); // Load addons when the component mounts

        // eslint-disable-next-line
    }, []); // Empty dependency array to run only once

    return (
        <>
            <Helmet>
                <title>Services</title>
                <link rel="canonical" href='https://touch-massagetherapy.com/services' />
            </Helmet>
            <h1 className='center-text glow fancy-font big-text-1'>Services</h1>
            <div className='services-container'>
                {services
                    .sort((a, b) => a.title > b.title ? 1 : -1) // Sort services by title
                    .map((item) => (
                        <ServiceItem key={item.id} item={item} /> // Assuming each service item has a unique 'id'
                    ))}
            </div>
            <p className='center-text bold callout'>*Add an additional 15 minutes: $22.50 Couples: $45 </p>
            <br />
            <h1 className='center-text glow fancy-font big-text-2'>Add-Ons</h1>
            <div className='services-container'>
                {addons
                    .sort((a, b) => a.title > b.title ? 1 : -1) // Sort addons by title
                    .map((item) => (
                        <AddonItem key={item.title} item={item} /> // Assuming each addon item has a unique 'id'
                    ))}
            </div>
        </>
    );
};

export default Services; // Ensure the component is exported correctly