import React from "react";
import "../css/WhatsNew.css";

const WhatsNew = () => {
  return (
    <div className="news-item">
      <header className="center-text big-text-3">Food Pantry Promotion</header>
      <p>
        Help support our Forney community! Bring a non-perishable food donation
        and get $5 off your next service. Use promo code FORNEY5OFF when you
        book. Valid until 11/30/24.
      </p>
    </div>
  );
};

export default WhatsNew;
