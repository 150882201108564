import React from 'react'

export const AddonItem = ({ item }) => {
    return (
        <div className='addon-item card'>
            <h2>{item.title} - ${item.price}</h2>
            <br />
            <p>{item.description}</p>
            {
                item.options && item.options.length > 0 && (
                    <ul>
                        {item.options.map((option, index) => (
                            <li key={index}>
                                <p>
                                    <span className='bold'>
                                        {option.title}:
                                    </span>
                                    &nbsp; {option.description}
                                </p>
                            </li>
                        ))}
                    </ul>
                )
            }
        </div>
    )
}

export default AddonItem; // Ensure the component is exported correctly
