import React, { useState } from "react";
import "../css/Popup.css";

const Popup = (props) => {
  const [isOpen, setIsOpen] = useState(props.trigger);

  return isOpen ? (
    <div className="popup">
      <div className="popup-inner">
        <button className="close-btn" onClick={() => setIsOpen(false)}>
          close
        </button>
        {props.children}
      </div>
    </div>
  ) : (
    ""
  );
};

export default Popup;
