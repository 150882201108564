import {
    TEAM_MEMBERS_LOADED
} from "../types";

// Reducer function
const teamReducer = (state, action) => {
    switch (action.type) {
        case TEAM_MEMBERS_LOADED:
            return {
                ...state,
                teamMembers: action.payload
            };
        default:
            return state;
    }
};

export default teamReducer;