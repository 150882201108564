import React from 'react';
import '../../css/Footer.css';

const footer = () => {
    return (
        <footer className='footer'>
            <div className='footer-content'>
                <div className='biz-hours'>
                    <h4>Business Hours:</h4>
                    <p>Mon-Sat: 9am - 6pm</p>
                    <p>Mon-Sat: 6pm - 9pm By Appointment Only</p>
                    <p>Sun: 9am - 9pm By Appointment Only</p>
                </div>
                <div className='social-links'>
                    <a href='https://www.facebook.com/profile.php?id=61565733715315' aria-label='Follow us on Facebook' target='_blank' rel='noreferrer'>
                        <i className='fab fa-facebook fa-2x'></i>
                    </a>
                    <a href='https://www.instagram.com/touchmassage.therapy' aria-label='Follow us on Instagram' target='_blank' rel='noreferrer'>
                        <i className='fab fa-instagram fa-2x'></i>
                    </a>
                </div>
                <address>
                    <span className='bold'>Touch Massage Therapy</span>
                    <br />
                    522 Pinson Rd.
                    <br />
                    Forney, TX 75126
                    <br />
                    Phone: <a href='tel:469-520-0012'>(469) 520-0012</a>
                    <br />
                    <a href="mailto:info@touch-massagetherapy.com"> info@touch-massagetherapy.com</a>
                </address>
            </div>
            <br />
            <p>Massage Establishment License - <span className='bold'>ME9070</span></p>
            <br />
            <p>&copy; 2024 Touch Massage Therapy</p>

        </footer>
    )
}

export default footer;
