import React from "react";
import Reviews from "./Reviews";
import "../css/Testimonials.css";

const Testimonials = () => {
  return (
    <div className="reviews">
      <Reviews />;
    </div>
  );
};

export default Testimonials;
