import React, { useReducer } from "react";
import { TeamContext } from "../Contexts.js";
import teamReducer from "./TeamReducer.js";

const TeamState = props => {
    const initialState = {
        teamMembers: [
            {
                id: 2,
                image_path: 'images/angela-headshot.jpg',
                first_name: 'Angela',
                last_name: 'Horton',
                headline: 'Licensed Massage Therapist & Reiki Master',
                description: 'After 25 years at AT&T, Angela followed her passion for healing to become a Licensed Massage Therapist and Reiki Master. As a mother of five, she brings a nurturing and intuitive approach to her work, with a special focus on clients with special needs. Angela offers a holistic approach to wellness, addressing physical, emotional, mental and spiritual aspects of healing. Dedicated to creating a supportive and healing environment, Angela ensures that both her clients and employees thrive in a space designed for growth and well-being.',
            },
            {
                id: 1,
                image_path: 'images/ginger-headshot.jpg',
                first_name: 'Ginger',
                last_name: 'Kratzmeyer',
                headline: 'Licensed Massage Therapist',
                description: 'Ginger, a seasoned Licensed Massage Therapist, is devoted to your well-being and the transformative power of healing touch. With over 11 years of experience, she has mastered the art of deep-tissue massage, the soothing warmth of hot stone therapy, and the precision of trigger point release. But it’s not just about technique: Ginger’s commitment to exceptional customer service ensures that every client leaves their massage session feeling blissfully relaxed and eager to book their next appointment.',
            },
            {
                id: 4,
                image_path: 'images/megan-headshot.jpg',
                first_name: 'Megan',
                last_name: 'Hubbard',
                headline: 'Licensed Massage Therapist',
                description: 'With a decade of experience in massage therapy, Megan has developed effective treatment plans for patients with chronic pain and back issues. Her expertise includes Swedish, Deep Tissue, Reflexology, and Hot Stone therapies.  Megan thrives on challenges and continually seeks to expand her skills, focusing on conditions such as post-surgery recovery, sciatica, arthritis, fibromyalgia, and tendonitis. She prioritizes open communication and genuine care, always ready to lend a sympathetic ear. Whether in a therapeutic or spa setting, her goal is to provide a relaxing and healing experience tailored to each individual’s needs.',
            },
            {
                id: 5,
                image_path: 'images/misti-headshot.jpg',
                first_name: 'Misti',
                last_name: 'Sterling',
                headline: 'Licensed Massage Therapist',
                description: 'As a skilled and experienced massage therapist with 11 years of practice, I specialize in deep tissue therapy, hot stone treatments, and relaxation techniques to provide a tailored, holistic approach for each client. My approoach combines precision and care to relieve muscle tension, reduce stress, and promote overall well-being. Whether you are seeking relief from chronic pain, or simply looking to unwind, my goal is to provide a calming and restorative environment that helps you achieve optimal balance and relaxation.',
            },
            {
                id: 6,
                image_path: 'images/renee-headshot.jpeg',
                first_name: 'Renee',
                last_name: 'Handley',
                headline: 'Studio Coordinator',
                description: 'A California native now thriving in Texas, Renee brings over two decades of expertise in the beauty and wellness industry. With a strong commitment to customer service, she believes that every client deserves an exceptional experience. Renee’s passion for her work shines through in her interactions, making her a trusted professional dedicated to enhancing the lives of those she serves.'
            },
            {
                id: 3,
                image_path: 'images/eldri-headshot.jpg',
                first_name: 'Eldri',
                last_name: 'Wheeler',
                headline: 'Licensed Massage Therapist',
                description: 'Eldri brings a natural talent for healing touch, combining strength, intuition, and skill to create a truly restorative massage experience. From melting away tension with Swedish techniques to targeting stubborn aches with deep tissue work, they tailor each session to meet your needs. Eldri’s calming energy and exceptional facial and scalp massages leave clients refreshed, renewed, and ready to take on the day—or savor a well-deserved nap.'
            }
        ],
        filtered: null,         // Array of filtered therapists
        current: null
    };

    const [state, dispatch] = useReducer(teamReducer, initialState);

    // Load Team Members
    const loadTeamMembers = async () => {
        await dispatch({
            type: 'TEAM_MEMBERS_LOADED',
            payload: initialState.teamMembers
        });
    };

    return (
        <TeamContext.Provider
            value={{
                teamMembers: state.teamMembers,
                current: state.current,
                filtered: state.filtered,
                loadTeamMembers
            }}>
            {props.children}
        </TeamContext.Provider>
    );
};

export default TeamState;