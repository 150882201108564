import {
    SERVICES_LOADED,
    ADDONS_LOADED
} from "../types";

// Reducer function
const serviceReducer = (state, action) => {
    switch (action.type) {
        case SERVICES_LOADED:
            return {
                ...state,
                services: action.payload
            };
        case ADDONS_LOADED:
            return {
                ...state,
                addons: action.payload
            };
        default:
            return state;
    }
};

export default serviceReducer;