import React from 'react';
import { Link } from 'react-router-dom';
import '../../css/Navbar.css';

const navbar = () => {
    return (
        <nav className="navbar">
            <div>
                <Link className='fancy-font big-text-3' to='/'>Touch Massage Therapy</Link>
            </div>
            <ul className='nav-links'>
                <li>
                    <Link to='/'>Home</Link>
                </li>
                <li>
                    <Link to='/team'>Our Team</Link>
                </li>
                <li>
                    <Link to='/services'>Services</Link>
                </li>
                <li>
                    <Link to='/jobs'>Jobs</Link>
                </li>
                {/* <li>
                    <Link to='/whatsnew'>What's New</Link>
                </li> */}
                <li>
                    <Link to='/testimonials'>Testimonials</Link>
                </li>
                <li>
                    <Link to='https://go.booker.com/location/TouchMassageL53735/buy/gift-certificate' target='_blank'>Gift Certificates</Link>
                </li>
                <li>
                    <button className='book-now'>
                        <a href="https://go.booker.com/location/TouchMassageL53735">Book Now!!</a>
                    </button>
                </li>
            </ul>
        </nav>
    )
}

export default navbar
