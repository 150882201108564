import React from 'react';
import '../../css/Team.css';

const teamMemberItem = ({ member }) => {
    return (
        <div className='team-member-item card'>
            <div className='item-header'>
                <img src={member.image_path} alt='Team Member' />
                <div className='name-title'>
                    <h2 className='glow'>{member.first_name} {member.last_name}</h2>
                    <h3>{member.headline}</h3>
                </div>
            </div>
            <div className='item-body'>
                <p>{member.description}</p>
            </div>
        </div>
    )
}

export default teamMemberItem
