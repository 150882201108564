import React from "react";
import "../../css/Jobseeker.css";
import { Helmet } from "react-helmet";

const JobseekerForm = () => {
  return (
    <>
      <Helmet>
        <title>Job Interest</title>
        <link rel="canonical" href="https://touch-massagetherapy.com/jobs" />
      </Helmet>
      <div className="job-interest-form">
        <p>
          If you are an LMT interested in working with us, please fill out the
          following info and we will get back to you as soon as possible. Thank
          you!
        </p>
        <form
          className="form"
          action="https://formspree.io/f/xrbzndbk"
          method="POST"
        >
          <label htmlFor="full-name">Full Name:</label>
          <input type="text" name="full-name" />

          <label htmlFor="email">Email Address:</label>
          <input type="emailyarn" name="email"></input>

          <label htmlFor="phone">Phone Number:</label>
          <input type="tel" name="phone"></input>

          <label htmlFor="years-experience">Years experience as an LMT:</label>
          <input type="number" name="years-experience"></input>

          <button type="submit">Send</button>
        </form>
      </div>
    </>
  );
};

export default JobseekerForm;
