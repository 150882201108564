import { Component } from "react";

class MindBodyReviews extends Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://d1yw3duy3i4qiv.cloudfront.net/js/sdk-v1.js";
    script.async = true;
    document.body.appendChild(script);

    // Initialize the widget after the script loads
    script.onload = () => {
      if (window.FRED) {
        window.FRED.init({
          locationId: "13afa264-3aa0-42ee-b9d9-543defa6cd9b",
          perPage: 5,
          layout: "carousel",
          background: "fff",
          titleHexColor: "2a443c",
          starHexColor: "fcc415",
        });
      }
    };
  }

  render() {
    return <div className="reviews" data-fred-widget-reviews></div>;
  }
}

export default MindBodyReviews;
